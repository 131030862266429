import axios from 'axios'
import Vue from 'vue'
import router from '../router/router'
import { MessageBox, Message, Notification } from 'element-ui'

const service = axios.create({
  timeout: 80000,
  baseURL: 'https://rwapi.xinzhibang168.com/',
})

service.interceptors.request.use(
  config => {
    // if (store.getters.token) {
    //   config.headers['X-Token'] = getToken()
    //   config.headers['Authorization'] = getToken()
    // }
	//config.headers['sessionId'] = getToken()
	let token=window.sessionStorage.getItem('token')
	if(token!=null){
		config.url = `${config.url}?token=${token}` 
	}
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(response => {
    const res = response.data
	console.log('code',res.code)
	if(res.code==0){
		  //store.dispatch('user/resetUserRouter')
		  Message({message:res.msg, showClose: true, type: 'warning' })
		  /*
		  setTimeout(()=>{
			   location.href = '/eleven'
		  },1000)
		  */
	}
	return res
	/*
    if (res.status !== 200) {
      if (res.status === 50008 || res.status === 50012 || res.status === 50014) {
        MessageBox.confirm('登录被退出，请重新登录！', '确认退出', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // store.dispatch('user/resetToken').then(() => {
          //   location.reload()
          // })
        })
      }
      if (res.status === 40101) {
        Message({ title: '异常', message: res.message, showClose: true, type: 'warning' })
        return Promise.reject('error');
      }
      return Promise.reject(
        Notification({ title: '异常', message: res.message, showClose: true, type: 'warning' })
      )
      return res
    } else {
      return res
    }
	*/
	
  },
  error => {
    Message({ message: error.message, showClose: true, type: 'error', duration: 5 * 1000, title: '异常1'})
    return Promise.reject(error)
  }
)



export default service
